<template>
	<div class="gg-container">
		<el-button type="danger" @click="closeDrawer"
							 style="position: absolute; display: flex; justify-content: center; align-items: center; border: 0; border-radius: 4px 0 0 4px; top: 140px; left: -40px;width: 40px; height: 40px;">
			<i class="iconfont icon-cha" style="font-size: 24px"></i>
		</el-button>
		<div class="required"></div>
		<span>处方</span>
		<div style="display: flex">
			<!--内容区域-->
			<div>
				<div class="pres-images">
					<div class="pres-image-item">
						<img
							v-for="(img,index) in srcList"
							:key="index"
							style="width: 80px; height: 80px;cursor: pointer;margin-right: 5px"
							:src="img"
							:preview-src-list="srcList"
							@click="handlerSelectPic(img)"
						/>
					</div>
				</div>
				<!--输入-->
				<div class="pres-basic-info">
					<el-form :model="ruleForm" :rules="rules" size="mini" :inline="true" ref="ruleForm">
						<div style="display: flex;">
							<el-form-item class="pres-basic-info-item" label="来源">
								<el-radio-group v-model="ruleForm.presSource">
									<el-radio :label="1">内部</el-radio>
									<el-radio :label="2">外部</el-radio>
								</el-radio-group>
							</el-form-item>
							
							<el-form-item class="pres-basic-info-item" label="条形码" v-if="ruleForm.presSource == '1'">
								<el-input class="from-item-length150" v-model.trim="ruleForm.barCode" autocomplete="off"  placeholder="输入条形码"></el-input>
								<el-button icon="el-icon-search" circle @click="handleCf"></el-button>
							</el-form-item>
							
							
							<el-form-item class="pres-basic-info-item" label="医师审核">
								<el-radio-group v-model="ruleForm.isDoctorExamine">
									<el-radio :label="1">是</el-radio>
									<el-radio :label="0">否</el-radio>
								</el-radio-group>
							</el-form-item>
							
							<el-form-item class="pres-basic-info-item" label="是否代煎">
								<el-radio-group v-model="ruleForm.isDecoct">
									<el-radio :label="1">是</el-radio>
									<el-radio :label="0">否</el-radio>
								</el-radio-group>
							</el-form-item>
							
						</div>
						
						<!--汤剂用药说明-->
						<div style="display: flex">
							<el-form-item class="pres-basic-info-item" label="医师" prop="doctor">
								<el-select
									class="from-item-length150"
									size="mini"
									v-model="ruleForm.doctor"
									filterable
									remote
									reserve-keyword
									placeholder="请输入关键词"
									@change="handlerChangeDoctor"
									:remote-method="handlerSearchDoctor"
									:loading="loading">
									<el-option
										v-for="item in doctorListOptions"
										:key="item.id"
										:label="item.doctor_name"
										:value="item.id"
									>
									</el-option>
								</el-select>
							</el-form-item>
							<el-form-item class="pres-basic-info-item" label="剂型" prop="dosageId">
								<el-select
									class="from-item-length150"
									v-model="ruleForm.dosageId"
									filterable
									placeholder="请选择剂型"
									@change="handleSelectDosageType">
									<el-option
										v-for="item in dosageListOptions"
										:key="item.id"
										:label="item.dosage_name"
										:value="item.id">
									</el-option>
								</el-select>
							</el-form-item>
							<el-form-item class="pres-basic-info-item" label="关联医师" prop="relationDoctorId">
								<el-select
									class="from-item-length150"
									size="mini"
									v-model="ruleForm.relationDoctorId"
									filterable
									allow-create
									reserve-keyword
									placeholder="请输入关键词"
									@change="handlerChangeDoctor"
									:loading="loading">
									<el-option
										v-for="item in relationDoctorListOptions"
										:key="item.id"
										:label="item.doctor_name"
										:value="item.id"
									>
									</el-option>
								</el-select>
							</el-form-item>
							<el-form-item v-if="this.selectDosageProcessOptions.length > 0" class="pres-basic-info-item" label="加工方式" prop="processId">
								<el-select
									class="from-item-length150"
									v-model="ruleForm.processId"
									filterable
									placeholder="选择加工方式"
									@change="handleSelectDosageProcess">
									<el-option
										v-for="item in selectDosageProcessOptions"
										:key="item.id"
										:label="item.process_name"
										:value="item.id">
									</el-option>
								</el-select>
							</el-form-item>
						
							<el-form-item v-if="this.ruleForm.dosageId == 4  &&
							(this.ruleForm.processId == 2 || this.ruleForm.processId == 5) "
														class="pres-basic-info-item" label="辅料备注" prop="processId">
								<el-select
									class="from-item-length150"
									v-model="ruleForm.assistRemark"
									filterable
									placeholder="选择辅料备注"
									@change="handleSelectDosageProcess">
									<el-option
										v-for="item in gaoFangOptions"
										:key="item.value"
										:label="item.value"
										:value="item.value">
									</el-option>
								</el-select>
							</el-form-item>
							
						</div>
						<el-form-item v-if="curDosageName == '汤剂'" class="pres-basic-info-jixin" label="用量说明" prop="medicine">
							<div style="display: flex; justify-content: space-between">
								<div class="pres-basic-info-jixin-item">共
									<el-input @blur="handlerTangjiCountBlur()" v-model.trim="ruleForm.count" autocomplete="off"  class="from-item-length"></el-input>
									剂
								</div>
								<div class="pres-basic-info-jixin-item">每日
									<el-input v-model.trim="ruleForm.perDayCount" autocomplete="off"  class="from-item-length"></el-input>
									剂
								</div>
								<div class="pres-basic-info-jixin-item">每剂
									<el-input v-model.trim="ruleForm.perCount" autocomplete="off"  class="from-item-length"></el-input>
									次
								</div>
								<div class="pres-basic-info-jixin-item">每次
									<el-input v-model.trim="ruleForm.perNum" autocomplete="off"  class="from-item-length"></el-input>
									ml
								</div>
							</div>
						</el-form-item>
						<!--草药用药说明-->
						<el-form-item v-if="curDosageName == '草药'" class="pres-basic-info-jixin" label="用量说明" prop="medicine">
							<div style="display: flex; justify-content: space-between">
								<div class="pres-basic-info-jixin-item">共
									<el-input v-model.trim="ruleForm.count" autocomplete="off"  class="from-item-length"></el-input>
									剂
								</div>
								<div class="pres-basic-info-jixin-item">每日
									<el-input v-model.trim="ruleForm.perDayCount" autocomplete="off"  class="from-item-length"></el-input>
									剂
								</div>
								<div class="pres-basic-info-jixin-item">每剂
									<el-input v-model.trim="ruleForm.perCount" autocomplete="off"  class="from-item-length"></el-input>
									次
								</div>
							</div>
						</el-form-item>
						<!--膏方用药说明-->
						<el-form-item v-if="curDosageName == '膏方' && curProcessName.includes('瓶装') " class="pres-basic-info-jixin" label="用量说明" prop="medicine">
							<div style="display: flex; justify-content: space-between">
								<div class="pres-basic-info-jixin-item">每日
									<el-input v-model.trim="ruleForm.perDayNum" autocomplete="off"  class="from-item-length"></el-input>
									次
								</div>
								<div class="pres-basic-info-jixin-item">每次
									<el-input v-model.trim="ruleForm.perNum" autocomplete="off"  class="from-item-length"></el-input>
									ml
								</div>
							</div>
						</el-form-item>
						<el-form-item v-if="curDosageName == '膏方' && curProcessName.includes('小包装') " class="pres-basic-info-jixin" label="用量说明" prop="medicine">
							<div style="display: flex; justify-content: space-between">
								<div class="pres-basic-info-jixin-item">每日
									<el-input v-model.trim="ruleForm.perDayNum" autocomplete="off"  class="from-item-length"></el-input>
									次
								</div>
								<div class="pres-basic-info-jixin-item">每次
									<el-input v-model.trim="ruleForm.perDayCount" autocomplete="off"  class="from-item-length"></el-input>
									袋
								</div>
								<div class="pres-basic-info-jixin-item">1袋
									<el-input v-model.trim="ruleForm.perNum" autocomplete="off"  class="from-item-length"></el-input>
									ml
								</div>
							</div>
						</el-form-item>
						<!--丸剂用药说明-->
						<el-form-item v-if="curDosageName == '丸剂'" class="pres-basic-info-jixin" label="用量说明" prop="medicine">
							<div style="display: flex; justify-content: space-between">
								<div class="pres-basic-info-jixin-item">每日
									<el-input v-model.trim="ruleForm.perDayNum" autocomplete="off"  class="from-item-length"></el-input>
									次
								</div>
								<div class="pres-basic-info-jixin-item">每次
									<el-input v-model.trim="ruleForm.perDayCount" autocomplete="off"  class="from-item-length"></el-input>
									袋
								</div>
								<div class="pres-basic-info-jixin-item">每袋
									<el-input v-model.trim="ruleForm.perNum" autocomplete="off"  class="from-item-length"></el-input>
									克
								</div>
							</div>
						</el-form-item>
						<!--其它研粉用药说明-->
						<el-form-item v-if="curDosageName == '其他' && curProcessName == '研粉'  " class="pres-basic-info-jixin" label="用量说明" prop="medicine">
							<div style="display: flex; justify-content: space-between">
								<div class="pres-basic-info-jixin-item">每日
									<el-input v-model.trim="ruleForm.perDayNum" autocomplete="off"  class="from-item-length"></el-input>
									次
								</div>
								<div class="pres-basic-info-jixin-item">每次
									<el-input v-model.trim="ruleForm.perDayCount" autocomplete="off"  class="from-item-length"></el-input>
									袋
								</div>
								<div class="pres-basic-info-jixin-item">1袋
									<el-input v-model.trim="ruleForm.perNum" autocomplete="off"  class="from-item-length"></el-input>
									克
								</div>
							</div>
						</el-form-item>
						<!--其它胶囊用药说明-->
						<el-form-item v-if="curDosageName == '其他' && curProcessName == '胶囊' " class="pres-basic-info-jixin" label="用量说明" prop="medicine">
							<div style="display: flex; justify-content: space-between">
								<div class="pres-basic-info-jixin-item">每日
									<el-input v-model.trim="ruleForm.perDayNum" autocomplete="off"  class="from-item-length"></el-input>
									次
								</div>
								<div class="pres-basic-info-jixin-item">每次
									<el-input v-model.trim="ruleForm.perNum" autocomplete="off"  class="from-item-length"></el-input>
									粒
								</div>
							</div>
						</el-form-item>
						
						
						<!--使用说明-->
						<div style="display: flex">
							<el-form-item class="pres-basic-info-item" label="用药说明">
								<el-select
									style="width:300px"
									v-model.trim="ruleForm.medicationMethod"
									placeholder="用药说明"
									size="mini"
									@change="handlerMedicationMethodCustom"
									clearable
									allow-create
									filterable
								>
									<el-option
										v-for="item in medicationMethodOptions"
										:key="item.value"
										:label="item.label"
										:value="item.value">
									</el-option>
								</el-select>
								<!--<el-input class="from-item-length400" type="textarea" :rows="1"-->
								<!--					v-model.trim="ruleForm.medicationMethod"-->
								<!--					autocomplete="off"-->
								<!--					placeholder="填填写写用药说明"></el-input>-->
							</el-form-item>
							<el-form-item class="pres-basic-info-item" label="服药禁忌">
									<el-select
										style="width:550px"
										v-model.trim="ruleForm.taboo"
										placeholder="服药禁忌"
										size="mini"
										clearable
										multiple
										@change="handleSelectTaboo"
									>
										<el-option
											v-for="item in tabooOptions"
											:key="item.value"
											:label="item.label"
											:value="item.value">
										</el-option>
									</el-select>
								<!--<el-input class="from-item-length400" type="textarea" :rows="1"-->
								<!--					v-model.trim="ruleForm.taboo"-->
								<!--					autocomplete="off"-->
								<!--					placeholder="请填写服药禁忌"></el-input>-->
							</el-form-item>
						</div>
						<div style="display: flex">
							<el-form-item class="pres-basic-info-item" label="医嘱" prop="doctorAdvice">
								<el-input class="from-item-length400" type="textarea" :rows="1"
													v-model.trim="ruleForm.doctorAdvice"
													autocomplete="off"
													placeholder="请填写医嘱"></el-input>
							</el-form-item>
							<el-form-item class="pres-basic-info-item" label="配药时间" prop="makeDrugDay">
								<el-radio-group v-model="ruleForm.makeDrugDay">
									<el-radio :label="0">常规</el-radio>
									<el-radio :label="1"><el-input :disabled="ruleForm.makeDrugDay == 0" type='number' v-model.trim="ruleForm.makeDrugDayNumber" autocomplete="off"  class="from-item-length"></el-input>
										日后配药</el-radio>
								</el-radio-group>
							</el-form-item>
						</div>
						
						<!--诊断-->
						<div style="display: flex;" v-if="ruleForm.isDoctorExamine ==0 " >
							<el-form-item class="pres-basic-info-item" label="诊断" prop="diseases" style="width: 100%">
								<el-select
									class="from-item-length400"
									size="mini"
									multiple
									v-model="ruleForm.diseases"
									filterable
									remote
									reserve-keyword
									placeholder="请输入关键词"
									@change="handlerChangeDiseases"
									:remote-method="handlerSearchDiseases"
									:loading="loading">
									<el-option
										v-for="item in diseasesListOptions"
										:key="item.id"
										:label="item.disease_name"
										:value="item.id"
									>
									</el-option>
								</el-select>
							</el-form-item>
						</div>
						<div style="display: flex;" v-else >
							<el-form-item class="pres-basic-info-item" label="诊断" style="width: 100%">
								<el-select
									class="from-item-length400"
									size="mini"
									multiple
									v-model="ruleForm.diseases"
									filterable
									remote
									reserve-keyword
									placeholder="请输入关键词"
									@change="handlerChangeDiseases"
									:remote-method="handlerSearchDiseases"
									:loading="loading">
									<el-option
										v-for="item in diseasesListOptions"
										:key="item.id"
										:label="item.disease_name"
										:value="item.id"
									>
									</el-option>
								</el-select>
							</el-form-item>
						</div>
						
						<!--病情描述-->
						<el-form-item class="pres-basic-info-item" label="病情描述" prop="desc">
							<el-input class="from-item-length400" type="textarea" :rows="1"
												v-model.trim="ruleForm.desc"
												autocomplete="off"
												placeholder="病情描述"></el-input>
						</el-form-item>
						
						
						<!--添加药材-->
						<div style="display: flex; justify-items: center">
							<span style="color: #ff4949; margin-top: 4px">*</span>
							<span style="font-weight: bolder;font-size: 14px;color: #606266;padding: 0 12px 4px 4px;">药材</span>
							<span style="font-weight: bolder;font-size: 14px;color: #606266;padding: 0 12px 4px 4px;">药材金额 {{drugTotalPrice}}</span>
							<span style="font-weight: bolder;font-size: 14px;color: #606266;padding: 0 12px 4px 4px;">加工费 {{drugProcessPrice}}</span>
							<span style="font-weight: bolder;font-size: 14px;color: #606266;padding: 0 12px 4px 4px;">总金额 {{allTotalPrice}}</span>
							<!--<span style="font-weight: bolder;font-size: 14px;color: #606266;padding: 0 12px 4px 4px;">当前数量: {{addDrugList.length}}</span>-->
						</div>
						
						<div style=" max-height: 500px; overflow-y: auto">
							<div class="drug-list" style="font-size: 14px; ">
								<!--单个药品-->
								<div v-for="(item, index) in addDrugList"
										 :key="item" class="drug-list-item">
									<el-select
										:disabled="item.flag == true"
										:ref="'select'+index"
										class="from-item-length200"
										size="mini"
										v-model="item.drugId"
										filterable
										remote
										reserve-keyword
										placeholder="请输入关键词"
										@change="(val) => handlerChangeDrug(val, index)"
										:remote-method="handlerSearchDrug"
										@keyup.enter.native="handlerAddDrugSelectIndex(item, index)"
										:loading="loading">
										<el-option
											v-for="item in searchDrugOptions"
											:key="item.drug_id"
											:label="item.drug_name"
											:value="item.drug_id"
										>
										<span style="float: left">{{ item.drug_name }}</span>
      									<span style="float: right; color: #8492a6; font-size: 13px;font-size:12px;">{{ item.unit_price }}</span>
										</el-option>
									</el-select>
									<el-input
										:ref="'input'+index"
										type='number'
										:min="0"
										style="width: 80px; margin-left: 5px; margin-right: 5px"
										size="mini"
										v-model.trim="item.count"
										autocomplete="off"
										@focus="handlerInputFocus(item, index)"
										@input="handlerInputCount(item, index)"
										@blur="handlerInputBlur(item,index)"
										@keyup.enter.native="handlerAddDrugInputIndex(item, index)"
									/>
									<div style="margin-right: 10px; margin-top: 3px">{{item.unitName != '个' ? '克' : '个'}}</div>
									<el-select
										class="from-item-length120"
										size="mini"
										v-model="item.friedName"
										filterable
										remote
										reserve-keyword
										placeholder="可以选择煎法"
										@change="(val) => handlerChangeFired(val, index)">
										<el-option
											v-for="item in firedOptions"
											:key="item.id"
											:label="item.fried_name"
											:value="item.id"
										>
										</el-option>
									</el-select>
									<el-button @click="handlerDelDrug(item, index)" size="mini" type="danger" icon="el-icon-delete" circle></el-button>
								</div>
							</div>
							
							<div class="search-container-fn-input">
								<el-button
									size="mini"
									type="success"
									style="padding: 6px 7px 7px 8px; margin-top: 10px"
									@click="handlerAddDrug">
									<i class="iconfont el-icon-plus" style="font-size: 13px"/>
									<span style="margin-left: 5px">添加药品</span>
								</el-button>
							</div>
							
							
							<el-form-item class="footer-button">
								<el-button style="margin-right: 50px" size="small" @click="handleCancel">取 消</el-button>
								<el-button size="small" type="primary" @click="handleConfirm('ruleForm')">确 定</el-button>
							</el-form-item>
						</div>
					</el-form>
				
				
				
				</div>
			</div>
			<!--放大旋转图片-->
			<div style="flex-shrink: 0;width: 730px;border: 1px solid #333; display: flex; flex-direction: column">
				<div class="img-top"  @mousewheel.prevent="handlerZoomPic">
					<img class="img-top-item"
							 id="rotatePic"
							 ref="rotatePic"
							 :src="curSelectPicUrl"
							 @mousedown="handlerMovePic"
					>
				</div>
				
				<div @click="handlerRotatePic('rotatePic')"
						 style="display: flex;justify-content: center; margin-bottom: 10px; margin-top: 20px; cursor:pointer;">
					<i style="font-size: 20px; margin-right: 5px" class="el-icon-refresh-right"/>
					<span style="font-size: 14px; color: #333">旋转</span>
				</div>
			</div>
			
		</div>
		
	</div>
</template>

<script>
import {getTcmDosage, getDoctorList,getDrugCalcProcessCost, getDrugInfoByDosageId,
	getDrugList,postAddPhotoDrug,getTcmFried, getDiseaseList, getDrugTcm,getInnerCf} from '@/api/drugs2.0';
import { math } from '@/utils/math.js'
export default {
  name: "drawOpenPres",
  props: {
    info: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  mounted() {},
	async created () {
  	this.srcList = this.info.pres_img
  	this.ruleForm.presSource = this.info.pres_source == '内部' ? 1 : 2
    this.curSelectPicUrl = this.info.pres_img[0]
		this.ruleForm.isDecoct = this.info.is_decoct
		await this._getTcmDosage()
		await this._getDoctorList()
		await this._getDrugTcm()
  },
 
  data () {
    let validateMedicine = (rule, value, callback) => {
      console.log(value)
      console.log(rule)
      console.log(callback)
      console.log('!!!!!!!!!!!!!!!!!!!!!!!!')
			if(this.curDosageName == '汤剂' && this.ruleForm.count > 0 && this.ruleForm.perDayCount  &&
        this.ruleForm.perCount > 0 && this.ruleForm.perNum > 0 ) {
        callback()
			} else if (this.curDosageName == '草药' && this.ruleForm.count > 0 && this.ruleForm.perDayCount > 0
				&& this.ruleForm.perCount > 0 ) {
        callback()
      } else if (this.curDosageName == '膏方' && this.curProcessName.includes('瓶装')  && this.ruleForm.perDayNum > 0 && this.ruleForm.perNum > 0) {
        callback()
      } else if (this.curDosageName == '膏方' && this.curProcessName.includes('小包装') && this.ruleForm.perDayNum > 0 && this.ruleForm.perDayCount> 0 && this.ruleForm.perNum > 0) {
        callback()
      }
      else if (this.curDosageName == '丸剂' && this.ruleForm.perDayNum > 0 && this.ruleForm.perDayCount > 0
        && this.ruleForm.perNum > 0 ) {
        callback()
      } else if (this.curDosageName == '其他' && this.curProcessName == '研粉' && this.ruleForm.perDayNum > 0
				&& this.ruleForm.perDayCount > 0 && this.ruleForm.perNum > 0 ) {
        callback()
      } else if (this.curDosageName == '其他' && this.curProcessName == '胶囊' && this.ruleForm.perDayNum > 0
				&& this.ruleForm.perNum > 0) {
        callback()
      } else {
        callback(new Error('请填写用量说明'))
      }
    }
    let validateMakeDrugDay = (rule, value, callback) => {	// 配药时间
      console.log(value)
			if(value == 0) {
        callback()
			}
      if (this.ruleForm.makeDrugDayNumber > 0) {
        callback()
      } else {
        callback(new Error('请填写配药时间'))
      }
    }
    return {
      doctorList: [],
      doctorListOptions:[],
      relationDoctorListOptions:[],//关联医师列表
      srcList: [], //传入照片处方图片列表
      curSelectPicUrl: '',	// 点击选中放大旋转的图片
			rotateDeg: 0,
			dosageListOptions: [],	// 剂型选择列表
			curDosageName: '',
      dosageIdBefore: '',
			curProcessName: '', // 如果选择其他, 当前加工方式不同填写用药说明不同
			selectDosageProcessOptions: [], // 选择的剂型加工方式
      firedOptions: [], //当前剂型的煎煮方法列表
      ruleForm: {
        barCode: '',
        doctor: '',
        presSource: 1, // == '内部' ? 1 : 2,
        isDoctorExamine: 0, // 医生审核 1 是 0 否
        isDecoct: 1, // 是否代煎 0 否 1是
        dosageId: '',	// 剂型类型id
        processId: '', // 加工方式id
        assistRemark: '',
        medicine: '', // 用量用法
				count: 0,	// 共多少剂 ---------- 用量说明
				perDayCount: 0, // 每日多少剂/每次多少袋
				perCount: 0,	// 每剂多少次
				perNum: 0,	// 每袋多少克/每次多少ml
				perDayNum: 0, // 每天多少次
				useDay: 0,	// 使用天数 ------------- 用量说明
        medicationMethod: '',	// 用药说明
        taboo: '', // 服药禁忌
        doctorAdvice: '', // 医嘱,
        makeDrugDay: 0,	// 配药时间
        makeDrugDayNumber: '', // 配药时间填写的具体时间
        diseases:'',	// 辨病或辨证诊断
        drugList: [],
        desc: ''
      },
      rules: {
        doctor: [
          { required: true, message: '请选择医师', trigger: 'blur' }
        ],
		relationDoctorId:[
          { required: true, message: '请选择关联医师', trigger: 'blur' }
        ],
        dosageId: [
          { required: true, message: '请选择剂型', trigger: 'blur' }
        ],
        processId: [
          { required: true, message: '请选择加工方式', trigger: 'blur' }
        ],
        medicine: [
          { validator: validateMedicine , message: '请填写用量说明', trigger: 'blur' }
        ],
        doctorAdvice: [
          { required: true, message: '请填写医嘱', trigger: 'blur' }
        ],
        makeDrugDay: [
          { validator: validateMakeDrugDay , message: '请填写配药时间', trigger: 'blur' }
        ],
        diseases: [
          { required: true, message: '请填写诊断', trigger: 'blur' }
        ],
        desc: [
          { required: true, message: '请填写病情描述', trigger: 'blur' }
        ],
      },
      searchDrugOptions: [],
			curDrug: {// 当前药品
        drugName: '',
        drugId: '',
				count: 0,
        singlePrice: 0,
        friedName: '',
			},
			addDrugList: [
        {
          drugName: '',
          drugId:'',
          count:'',
          friedName: '',
          compareDrugId:[],
        }
			],// 选中的药品列表
      addDrugListBefore: [], // 选中的药品列表判断重复
      loading: false,
			diseasesList: [],
      diseasesListOptions: [],
      medicationMethodOptions: [],	// 用药说明 单选+自定义
			tabooOptions: [],	// 字符串 数组
			drugTotalPrice: 0,
      drugProcessPrice: 0,
			allTotalPrice: 0,
			isNextFlag: false,
 			isPoison: false,	// 判断是否确认有毒通过
			isRepeat: false,
			gaoFangOptions: [
				{id: 1, value: '冰糖蜜'},
				{id: 2, value: '纯冰糖'},
				{id: 3, value: '纯蜂蜜'},
				{id: 4, value: '饴糖'}
			]
    }
  },
  methods: {
	handleCf(){
		getInnerCf(this.ruleForm.barCode).then(res=>{
			if(res.code == 200){
				if(Object.keys(res.data).length > 0){
					this._getFormData(res.data)
				}
				
			}
		})
	},
	_getFormData(data){
	this.ruleForm.doctor = data.doctor_id
    this.ruleForm.relationDoctorId = data.relation_doctor_id
    this.ruleForm.isDoctorExamine = data.is_doctor_examine
    this.ruleForm.dosageId = data.dosage_id
    this.dosageIdBefore = data.dosage_id
	this.drugTotalPrice = data.med_amount
	this.drugProcessPrice = data.process_amount
	this.allTotalPrice = data.total_amount
    // this.curDosageName = this.originPresDetail.dosage_name  // 剂型
    this.ruleForm.processId = data.process_id
    this.ruleForm.count = data.medicine.count
    this.ruleForm.perDayCount = data.medicine.per_day_count
    this.ruleForm.perCount = data.medicine.per_count
    this.ruleForm.perNum = data.medicine.per_num
    this.ruleForm.perDayNum = data.medicine.per_day_num
    this.ruleForm.useDay = data.medicine.use_day
    this.ruleForm.medicationMethod = data.medication_method
    this.ruleForm.taboo = data.taboo
    this.ruleForm.desc = data.desc
    this.ruleForm.doctorAdvice = data.doctor_advice
	this.addDrugList = []
    data.drug.forEach(item => {
      // this.searchDrugOptions = []
      var tmpOptions = {
        drug_id: item.drug_id,
        drug_name: item.drug_name,
      }

      var tmpAdd = {
        drugId: item.drug_id,
        drugName: item.drug_name,
        count: item.drug_count,
        friedName: item.fried_name,
				singlePrice: item.single_price,
        specs: item.specs,
        specsValueList: [],
        unitName: item.unit_name,
        poisonType: item.poison_type,
        maxPoisonCount: item.max_poison_count,
        compareDrugId: item.compare_drug_id,
				flag: true
      }
      if(tmpAdd.specs.length != 0) {
        tmpAdd.specs.forEach(spec => {
          tmpAdd.specsValueList.push(spec.specs_value)
        })
      }
      this.searchDrugOptions.push(tmpOptions)
      this.addDrugList.push(tmpAdd)
    })
  },
    async _getDrugTcm() {
      var res = await getDrugTcm()
      console.log(res)
			if(res.code == 200) {
			  res.data.drug_explain.forEach(item => {
          this.medicationMethodOptions.push({id: item, value: item})
				})
        res.data.drug_taboo.forEach(item => {
          this.tabooOptions.push({id: item, value: item})
        })
			}
		},
    async _getTcmDosage() { // 可选择剂型列表
      var res = await getTcmDosage()
      console.log(res)
			if(res.code == 200) {
				this.dosageListOptions = res.data
			}
		},
		async _getTcmFried() {
      var params = {
        dosage_id: this.ruleForm.dosageId
			}
			var res = await getTcmFried(params)
			if(res.code == 200) {
        console.log(res)
        this.firedOptions = res.data
			}
		},
		async _getDoctorList() {
      var res = await getDoctorList()
      console.log(res)
      if(res.code == 200) {
        this.doctorList = res.data.doctors
        this.doctorListOptions = res.data.doctors
        this.relationDoctorListOptions = res.data.relation_doctors
      }
		},
    handlerSelectPic(img) {
      this.curSelectPicUrl = img
			this.rotateDeg = 0
		},
    handlerRotatePic(element) {
      console.log('旋转')
			this.rotateDeg += 90
      var box = document.getElementById(element);
      box.style.transform = 'rotateZ(' + this.rotateDeg + 'deg)';
		},
    handlerMovePic(e) {
      e.preventDefault();
      // 获取元素
      var left = document.querySelector(".img-top");
      var img = document.querySelector(".img-top-item");
      var x = e.pageX - img.offsetLeft;
      var y = e.pageY - img.offsetTop;
      // 添加鼠标移动事件
      left.addEventListener("mousemove", move);
      function move(e) {
        img.style.left = e.pageX - x + "px";
        img.style.top = e.pageY - y + "px";
      }
      // 添加鼠标抬起事件，鼠标抬起，将事件移除
      img.addEventListener("mouseup", function() {
        left.removeEventListener("mousemove", move);
      });
      // 鼠标离开父级元素，把事件移除
      left.addEventListener("mouseout", function() {
        left.removeEventListener("mousemove", move);
      });
    },
    handlerZoomPic() {
      var zoom = parseInt(this.$refs.rotatePic.style.zoom) || 100;
      zoom += event.wheelDelta / 12;
      if (zoom >= 80 && zoom < 500) {
        this.$refs.rotatePic.style.zoom = zoom + "%";
      }
      return false;
		},
    // 改变剂型, 药材列表提示
    async _getDrugInfoByDosageId(dosageId) {
      var drugInfo = []
      var drugInfoFull = []
      this.addDrugList.forEach(item => {
        console.log(item)
        if( item.drugName != '' && item.count != '') {
          var tmp = {
            drug_id: item.drugId,
            drug_count: item.count,
            drug_name: item.drugName,
            fried_name: item.friedName
          }
          drugInfo.push(tmp)
          drugInfoFull.push(item)
        }
      })
			if(drugInfo.length == 0) {
			  this.dosageIdBefore = this.dosageIdBefore
			  return
			}
      var params = {
        dosage_id: this.ruleForm.dosageId,
        drug_info: drugInfo
      }
      var res = await getDrugInfoByDosageId(params)
			if(res.code == 200) {
			  var drugRetainByDosageList = []
				var drugDelByDosageList = []
			  var curDosageDrugList = res.data
        drugInfo.forEach(drug => {
          curDosageDrugList.forEach(dosageDrug => {
				    if(drug.durgId == dosageDrug.drug_id) {
              drugRetainByDosageList.push(drug)
						} else {
              drugDelByDosageList.push(drug)
						}
					})
				})
				var delDrugName = ''
        drugDelByDosageList.forEach(item => {
          delDrugName += item.drugName
        })
       	if(drugRetainByDosageList.length == 0) {
          drugInfo.forEach(item => {
            delDrugName += item.drug_name
					})
				}
				if(drugDelByDosageList.length > 0 || drugRetainByDosageList.length == 0) {
          this.$confirm(`此次切换${this.curDosageName} ${delDrugName} 将被删除`, {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(async () => {
            this.ruleForm.dosageId = dosageId
            this.addDrugList = JSON.parse(JSON.stringify(drugRetainByDosageList))
						this.addDrugList.push({
              drugName: '',
              drugId:'',
              count:'',
              friedName:'',
              compareDrugId: [],
            })
            if(this.ruleForm.dosageId == 2 ) {
              this.drugTotalPrice = math.divide(this.drugTotalPrice / this.ruleForm.count)
            }
            if(this.ruleForm.dosageId == 2 ) {
              this.drugTotalPrice = math.divide(this.drugTotalPrice / this.ruleForm.count)
            }
            var subtract
            var countUnit
            var totalCount = 0
            if(drugRetainByDosageList.length == 0) {
              for (const item of drugInfoFull) {
                console.log(item)
                if(item.unitName != '克') {
                  countUnit = math.multiply(item.count * 10000)  /  math.multiply(item.specsValueList[0] * 10000)
                  subtract = countUnit * Math.round((item.singlePrice) * 1000) / 1000
                  subtract = (Math.round(subtract * 100) / 100)
                  this.drugTotalPrice = math.subtract(this.drugTotalPrice - subtract)
                  this.drugTotalPrice = Math.round((this.drugTotalPrice) * 100) / 100
                } else {
                  subtract  = item.count * Math.round((item.singlePrice) * 1000) / 1000
                  subtract = (Math.round(subtract * 100) / 100)
                  this.drugTotalPrice = math.subtract(this.drugTotalPrice - subtract)
                  this.drugTotalPrice = Math.round((this.drugTotalPrice) * 100) / 100
                }
                if(this.ruleForm.dosageId == 2 ) {
                  this.drugTotalPrice = math.multiply(this.ruleForm.count * this.drugTotalPrice)
                }
                // 计算加工费
                // 汤剂 4 元/剂  草药没有  膏方掉接口  ,
                totalCount = 0		// 这是克数, 如果单位是个(罗汉果)不计算count , 其他的需要把 瓶 袋 包 转换成为 克
                this.addDrugList.forEach(item => {
                  if(item.unitName != '克') {
                    // totalCount += item.count * 1
                  } else if (item.unitName == '个') {
                    totalCount += 0
                  }else {
                    totalCount += item.count * 1
                  }
                })
                if(this.ruleForm.dosageId == 2) {
                  // 汤剂
                  this.drugProcessPrice = math.multiply(this.ruleForm.count * 4)
                } else if (this.ruleForm.dosageId == 12) {
                  this.drugProcessPrice = 0
                } else {
                  params = {
                    count: totalCount,
                    process_id: this.ruleForm.processId
                  }
                  console.log(params)
                  res = await getDrugCalcProcessCost(params)
                  if(res.code == 200) {
                    this.drugProcessPrice = res.data.cost
                  }
                }
                if(this.ruleForm.dosageId == 2 ) {
                  this.drugTotalPrice = math.multiply(this.ruleForm.count * this.drugTotalPrice)
                }
                this.allTotalPrice = math.add(this.drugProcessPrice + this.drugTotalPrice)
              }
            } else {
              for (const item of drugDelByDosageList) {
                if(item.unitName != '克') {
                  countUnit = math.multiply(item.count * 10000)  /  math.multiply(item.specsValueList[0] * 10000)
                  subtract = countUnit * Math.round((item.singlePrice) * 1000) / 1000
                  subtract = (Math.round(subtract * 100) / 100)
                  this.drugTotalPrice = math.subtract(this.drugTotalPrice - subtract)
                  this.drugTotalPrice = Math.round((this.drugTotalPrice) * 100) / 100
                } else {
                  subtract  = item.count * Math.round((item.singlePrice) * 1000) / 1000
                  subtract = (Math.round(subtract * 100) / 100)
                  this.drugTotalPrice = math.subtract(this.drugTotalPrice - subtract)
                  this.drugTotalPrice = Math.round((this.drugTotalPrice) * 100) / 100
                }
                if(this.ruleForm.dosageId == 2 ) {
                  this.drugTotalPrice = math.multiply(this.ruleForm.count * this.drugTotalPrice)
                }
                // 计算加工费
                // 汤剂 4 元/剂  草药没有  膏方掉接口  ,
                totalCount = 0		// 这是克数, 如果单位是个(罗汉果)不计算count , 其他的需要把 瓶 袋 包 转换成为 克
                this.addDrugList.forEach(item => {
                  if(item.unitName != '克') {
                    // totalCount += item.count * 1
                  } else if (item.unitName == '个') {
                    totalCount += 0
                  }else {
                    totalCount += item.count * 1
                  }
                })
                if(this.ruleForm.dosageId == 2) {
                  // 汤剂
                  this.drugProcessPrice = math.multiply(this.ruleForm.count * 4)
                } else if (this.ruleForm.dosageId == 12) {
                  this.drugProcessPrice = 0
                } else {
                  params = {
                    count: totalCount,
                    process_id: this.ruleForm.processId
                  }
                  console.log(params)
                  res = await getDrugCalcProcessCost(params)
                  if(res.code == 200) {
                    this.drugProcessPrice = res.data.cost
                  }
                }
                this.allTotalPrice = math.add(this.drugProcessPrice + this.drugTotalPrice)
              }
            }
          }).catch(() => {
            this.ruleForm.dosageId = this.dosageIdBefore
          });
				}
				
      }
    },
    handleSelectDosageType (e) {	// 选择剂型
      console.log('选择剂型')
      console.log(e)
			this.ruleForm.count = 7
			if(this.dosageIdBefore == '') {
			  this.dosageIdBefore = e
			}
			this.ruleForm.processId = ''
			this.dosageListOptions.forEach(item => {
			  if(item.id == e) {
          this.selectDosageProcessOptions = item.process
					this.curDosageName = item.dosage_name
        }
			})
			this._getTcmFried()
			this._getDrugInfoByDosageId(e)
			
    },
    handlerTangjiCountBlur() {
      this.drugProcessPrice = math.multiply(this.ruleForm.count * 4)
      this.allTotalPrice = math.add(this.drugProcessPrice + this.drugTotalPrice)
    },
    handleSelectDosageProcess(e) {
      console.log('选择剂型加工方式')
      console.log(e)
      this.selectDosageProcessOptions.forEach(item => {
        if(item.id == e) {
          this.curProcessName = item.process_name
        }
      })
		},
    handlerMedicationMethodCustom(e) {
      // 用药说明
		},
		// 搜索医生
    handlerSearchDoctor(query) {
      console.log(query)
      if (query !== '') {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.doctorListOptions = this.doctorList.filter(item => {
            return item.doctor_name.toLowerCase()
              .indexOf(query.toLowerCase()) > -1;
          });
        }, );
      } else {
        this.doctorListOptions = this.doctorList;
      }
		},
    handlerChangeDoctor(e) {
      console.log(e)
      console.log('添加的医生')
    },
		// 诊断搜索
    async handlerSearchDiseases(query) {
      console.log(query)
      if (query !== '') {
        this.loading = true;
        var res = await getDiseaseList({keyword: query})
        console.log(res)
				if(res.code == 200) {
          this.loading = false;
          res.data.data.forEach(item => { // 1 中医诊断 2 西医诊断
            if(item.type == 2) {
              item.disease_name += ' 西医诊断'
						} else {
              item.disease_name += ' 中医诊断'
						}
					})
          this.diseasesListOptions = res.data.data;
				}
				this.loading = false;
      } else {
        this.diseasesListOptions = [];
      }
		},
    handlerChangeDiseases(e) {
      console.log(e)
      console.log('添加的诊断')
		},
		// 服药禁忌
    handleSelectTaboo(e) {
      console.log(e)
			if(e.length > 3) {
        this.$message({
          type: 'error',
          message: '只能选择3个!'
        });
        this.ruleForm.taboo.pop()
			}
		},
		// 快捷搜索药材
    async handlerSearchDrug(query) {
      if(this.ruleForm.dosageId == '') {
        this.$message({
          type: 'error',
          message: '请选择剂型!'
        })
        return
			}
      if(this.ruleForm.dosageId == 4 || this.ruleForm.dosageId == 3 || this.ruleForm.dosageId == 13 ) {
        if(this.ruleForm.processId == '') {
          this.$message({
            type: 'error',
            message: '请选择加工方式!'
          })
          return
				}
			}
      if (this.ruleForm.dosageId == 4  && (this.ruleForm.processId == 2 || this.ruleForm.processId == 5 )) {
        if(this.ruleForm.assistRemark == '') {
          this.$message({
            type: 'error',
            message: '请选辅料备注!'
          })
          return
        }
      }
      if (query !== '') {
        this.loading = true;
        var params = {
          keyword: query,
					dosage: this.ruleForm.dosageId
				}
				var res = await getDrugList(params)
       	if (res.code == 200) {
       	  res.data.forEach(item => {
            if(item.specs.length != 0) {
              item.specs.forEach(spec => {
                item.drug_name += spec.specs_name
              })
            }
					})
          this.searchDrugOptions = res.data;
				}
        this.loading = false;
      } else {
        this.searchDrugOptions = [];
      }
		},
    async handlerChangeDrug(e, index) {
      console.log(e)
      console.log('添加的药品')  // 添加药品, 计算药品是否和已经添加药品是否相冲, 判断是否重复
			this.addDrugListBefore = JSON.parse(JSON.stringify(this.addDrugList))
			this.searchDrugOptions.forEach(item => {
			  if(item.drug_id == e) {
			    /// 判断相冲
          this.addDrugList.forEach(itemDrug => {
            itemDrug.compareDrugId.forEach(drugId => {
              if(drugId == e) {
                this.$confirm(`${item.drug_name} 和 ${itemDrug.drugName} 相冲`, {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning'
                }).then(() => {
                  setTimeout( () => {
                    console.log(index)
                    console.log("sdfasfasfa111")
                    this.$refs['select'+ index][0].focus()
									}, 100);
                }).catch(() => {
                  this.searchDrugOptions = [];
                });
              }
              console.log(item)
            })
            item.compare_drug_id.forEach(curDrugId => {
              if (curDrugId == itemDrug.drugId) {
                this.$confirm(`${item.drug_name} 和 ${itemDrug.drugName} 相冲`, {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning'
                }).then(() => {
                  setTimeout( () => {
                    console.log(index)
                    console.log("sdfasfasfa111")
                    this.$refs['select'+ index][0].focus()
                  }, 100);
                }).catch(() => {
                  this.searchDrugOptions = [];
                });
              }
            })
          })
					
			    this.addDrugList[index].drugName = item.drug_name
			    this.addDrugList[index].baseUnit = item.base_unit
			    this.addDrugList[index].unitName = item.unit_name
			    this.addDrugList[index].singlePrice = item.single_price
			    this.addDrugList[index].poisonType = item.poison_type// 是否有毒
			    this.addDrugList[index].maxPoisonCount = item.max_poison_count // 是否有毒的最大克数小于
			    this.addDrugList[index].maxPoisonCount = item.max_poison_count // 是否有毒的最大克数小于
			    this.addDrugList[index].compareDrugId = item.compare_drug_id // 相冲列表
          this.addDrugList[index].specsValueList = []
				
					if(item.specs.length != 0) {
						this.addDrugList[index].specs = item.specs
						item.specs.forEach(spec => {
							this.addDrugList[index].specsValueList.push(spec.specs_value)
						})
					}
          if(item.drug_id == '10808') {
            console.log(item)
            this.addDrugList[index].singlePrice = item.single_price / item.specs[0].specs_value
          }
				}
			})
		},
    handlerChangeFired(e, index) { // 选择煎法
      console.log(e)
      console.log('添加的煎煮方法')
      this.firedOptions.forEach(item => {
        if(item.id == e) {
          this.addDrugList[index].friedName = item.fried_name
        }
      })
		},
    async handlerDelDrug(item, index) {
      if(item.count == 0) {
        this.addDrugList.splice(index, 1)
        return
			}
      console.log('删除药品')
      var subtract
      if(this.ruleForm.dosageId == 2 ) {
        this.drugTotalPrice = math.divide(this.drugTotalPrice / this.ruleForm.count)
      }
			if(item.unitName != '克') {
        var countUnit = math.multiply(item.count * 10000)  /  math.multiply(item.specsValueList[0] * 10000)
        subtract = countUnit * Math.round((item.singlePrice) * 1000) / 1000
        subtract = (Math.round(subtract * 100) / 100)
        this.drugTotalPrice = math.subtract(this.drugTotalPrice - subtract)
        this.drugTotalPrice = Math.round((this.drugTotalPrice) * 100) / 100
        this.addDrugList.splice(index, 1)
			} else {
			  subtract  = item.count * Math.round((item.singlePrice) * 1000) / 1000
        subtract = (Math.round(subtract * 100) / 100)
        this.drugTotalPrice = math.subtract(this.drugTotalPrice - subtract)
        this.drugTotalPrice = Math.round((this.drugTotalPrice) * 100) / 100
        this.addDrugList.splice(index, 1)
			}
      if(this.ruleForm.dosageId == 2 ) {
        this.drugTotalPrice = math.multiply(this.ruleForm.count * this.drugTotalPrice)
      }
      // 计算加工费
      // 汤剂 4 元/剂  草药没有  膏方掉接口  ,
      var totalCount = 0		// 这是克数, 如果单位是个(罗汉果)不计算count , 其他的需要把 瓶 袋 包 转换成为 克
      this.addDrugList.forEach(item => {
        if(item.unitName != '克') {
          // totalCount += item.count * 1
				} else if (item.unitName == '个') {
          totalCount += 0
				}else {
        	totalCount += item.count * 1
        }
      })
      if(this.ruleForm.dosageId == 2) {
        // 汤剂
        this.drugProcessPrice = math.multiply(this.ruleForm.count * 4)
      } else if (this.ruleForm.dosageId == 12) {
        this.drugProcessPrice = 0
      } else {
        var params = {
          count: totalCount,
          process_id: this.ruleForm.processId
        }
        console.log(params)
        var res = await getDrugCalcProcessCost(params)
        if(res.code == 200) {
          this.drugProcessPrice = res.data.cost
        }
      }
      this.allTotalPrice = math.add(this.drugProcessPrice + this.drugTotalPrice)
		},
    handlerAddDrug() {
      // if(this.curDosageName != '草药') {
      //   if(this.isNextFlag == false && this.addDrugList.length > 0) {
      //     this.$message({
      //       type: 'error',
      //       message: '输入的数量不对!'
      //     })
			// 		this.curDrug.count = 0
      //     return;
      //   }
			// }

      var isNumber = true
      this.addDrugList.forEach(drug => {
        if((drug.count * 1) == 0) {
          isNumber = false
        }
      })

      // if(this.curDosageName != '草药') {
      //   if(this.isNextFlag == false && isNumber == false ) {
      //     this.$message({
      //       type: 'error',
      //       message: '输入的数量不对!'
      //     })
      //     this.curDrug.count = 0
      //     return
      //   }
			// }

      console.log('添加新药品空白')
			this.searchDrugOptions = []
			this.addDrugList.push({
				drugName: '',
				drugId:'',
				count:'',
        friedName:'',
        compareDrugId: [],
			})
		},
    handlerInputCount(item, index) { // 监听键盘输入, 计算输入规格是否满足
      console.log(item.count)
      console.log('输入了克数, 判断是否有毒')
      // 有毒判断
      if(item.poisonType == 1 && item.count >= item.maxPoisonCount) {
        this.isPoison = true
        this.$confirm(`${item.drugName}此药材达到最大有毒剂量,请谨慎!`, {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.isPoison = true
        }).catch(() => {
          this.isPoison = false
        });
      }
		},
    handlerInputFocus(item, index) {
      console.log(item)
      this.curDrug = JSON.parse(JSON.stringify(item))
    },
    async handlerInputBlur(item, index) { // 输入框失去焦点, 计算价格, 删除适合也需要重新计算价格
      this.isNextFlag = false
      var countUnit = 0
      var curDrugCountUnit = 0
			console.log(item)
      item.specsValueList.forEach(count => {
        if ( math.multiply(item.count * 10000)  %  math.multiply(count * 10000) == 0) {
          this.isNextFlag = true
        }
        if (item.unitName != '克') {
          countUnit = math.multiply(item.count * 10000)  /  math.multiply(item.specsValueList[0] * 10000)
        }
      })
      console.log(this.isNextFlag )
      console.log(item.specsValueList)
      // if(this.curDosageName != '草药') {
      //   if(this.isNextFlag == false && item.specsValueList.length > 0) {
      //     this.$message({
      //       type: 'error',
      //       message: `${item.drugName}输入的数量不对!`
      //     })
      //     this.curDrug.count = 0
			// 		this.addDrugList.forEach((item) => {
			// 		  if(item.drugId == this.curDrug.drugId) {
			// 		    item.count = item.originCount == null ? 0 : item.originCount
			// 			}
			// 		})
      //     return
      //   }
			// }

      this.addDrugList.forEach((item) => {
        if(item.drugId == this.curDrug.drugId) {
          item.originCount = item.count
        }
      })
			
      if(item.specsValueList.length == 0) {
        this.isNextFlag = true
      }

      // 删除原来
      if(this.ruleForm.dosageId == 2 ) {
        this.drugTotalPrice = math.divide(this.drugTotalPrice / this.ruleForm.count)
      }
      var subtract
      var add
      var result
      if(item.unitName != '克' && item.unitName != '个') {
        curDrugCountUnit = math.multiply(this.curDrug.count * 10000)  /  math.multiply(item.specsValueList[0] * 10000)
        subtract  = curDrugCountUnit  * Math.round((this.curDrug.singlePrice) * 1000) / 1000
        subtract = (Math.round(subtract * 100) / 100)
   
        curDrugCountUnit = math.multiply(item.count * 10000)  /  math.multiply(item.specsValueList[0] * 10000)
        add = curDrugCountUnit  * Math.round((item.singlePrice) * 1000) / 1000
        add = (Math.round(add * 100) / 100)
        add = math.add(this.drugTotalPrice + add)
        result = math.divide(add - subtract)
        result = (Math.round(result * 100) / 100)
        this.drugTotalPrice = Math.round((result) * 100) / 100
      } else {
        subtract  = this.curDrug.count * Math.round((this.curDrug.singlePrice) * 1000) / 1000
        subtract = (Math.round(subtract * 100) / 100)
        console.log(subtract)
        console.log(item.count * Math.round((item.singlePrice) * 1000) / 1000)
        add = item.count * Math.round((item.singlePrice) * 1000) / 1000
        add = (Math.round(add * 100) / 100)
        add = math.add(this.drugTotalPrice + add)
        console.log(add)
        result = math.divide(add - subtract)
        result = (Math.round(result * 100) / 100)
        console.log(result)
        this.drugTotalPrice = Math.round((result) * 100) / 100
      }
      if(this.ruleForm.dosageId == 2 ) {
        this.drugTotalPrice = math.multiply(this.ruleForm.count * this.drugTotalPrice)
        this.drugTotalPrice = Math.round(this.drugTotalPrice * 100) / 100
			}

      // 计算加工费
      // 汤剂 4 元/剂  草药没有  膏方掉接口  ,
      var totalCount = 0
      this.addDrugList.forEach(item => {
        if(item.unitName != '克') {
          // totalCount += item.count * 1
        } else if (item.unitName == '个') {
          totalCount += 0
        }else {
          totalCount += item.count * 1
        }
      })
      if(this.ruleForm.dosageId == 2) {
        // 汤剂
        this.drugProcessPrice = math.multiply(this.ruleForm.count * 4)
      } else if (this.ruleForm.dosageId == 12) {
        this.drugProcessPrice = 0
      } else {
        var params = {
          count: totalCount,
          process_id: this.ruleForm.processId
        }
        console.log(params)
        var res = await getDrugCalcProcessCost(params)
        if(res.code == 200) {
          this.drugProcessPrice = res.data.cost
        }
      }
      this.allTotalPrice = math.add(this.drugProcessPrice + this.drugTotalPrice)
      this.allTotalPrice = Math.round((this.allTotalPrice) * 100) / 100
      item.flag = true
    },
    handlerAddDrugSelectIndex(item,index) {// input输入框需要获取焦点, 判断药材是否重复// 判断是否重复
      this.isRepeat = false
      var itemOrigin = JSON.parse(JSON.stringify(item))
      console.log(item)
      console.log(itemOrigin)
      console.log(this.addDrugListBefore)
      this.addDrugListBefore.forEach(drug => {
        if(drug.drugName == itemOrigin.drugName) {
          this.$message({
            type: 'error',
            message: `${drug.drugName}重复!`
          })
          this.isRepeat = true
        }
      })
      if(this.isRepeat == true) {
        return
      }
      this.$refs['input'+index][0].focus()
      item.flag = true
    },
    async handlerAddDrugInputIndex(item, index) {
      this.isNextFlag = false
        item.specsValueList.forEach(count => {
          if (math.multiply(item.count * 10000)  %  math.multiply(count * 10000) == 0) {
            this.isNextFlag = true
          }
        })

      // if(this.curDosageName != '草药') {
      //   if(this.isNextFlag == false && item.specsValueList.length > 0) {
      //     this.$message({
      //       type: 'error',
      //       message: `${item.drugName}输入的数量不对!`
      //     })
      //     this.curDrug.count = 0
      //     return
      //   }
			// }
 
      if(item.specsValueList.length == 0) {
        this.isNextFlag = true
      }
			
      if(index == this.addDrugList.length - 1 && item.count > 0 && item.drugName.trim() != '') {
        this.searchDrugOptions = []
        this.addDrugList.push({
          drugName: '',
          drugId:'',
          count:'',
          friedName:'',
          compareDrugId: [],
        })
        this.$nextTick(()=>{
					index++
          console.log(this.$refs['select'+ index][0].focus())
				})
				
			}
		},	// 添加药品到药品列表
    // 关闭抽屉出发上一个页面数据
    closeDrawer () {
      this.$emit('closeDrawer', false)
    },
    handleCancel() {
      this.closeDrawer()
		},
    handleConfirm() {
      this.$refs['ruleForm'].validate(async (valid) => {
        if (valid) {
          // 组装数据发送添加照方抓药
					var makeDrugDayNumber
					var drugList = []
					if(this.ruleForm.makeDrugDay == 0) {
            makeDrugDayNumber = 0
					} else {
            makeDrugDayNumber = this.ruleForm.makeDrugDayNumber
					}
					this.addDrugList.forEach(item => {
					  if(item.drugId != '' && item.count > 0) {
              var tmp = {
                drug_id: item.drugId,
                drug_count: item.count,
                drug_name: item.drugName,
                fried_name: item.friedName
              }
              drugList.push(tmp)
						} else if (item.drugId != '' && item.count <= 0) {
              this.$message({
                type: 'error',
                message: '有药品没输入数量!'
              })
						}
					})
					var id = this.info.id
					var data = {
            pres_source : this.ruleForm.presSource,
            bar_code: this.ruleForm.barCode,
            doctor_id: this.ruleForm.doctor,
            relation_doctor_id: this.ruleForm.relationDoctorId,
            is_doctor_examine: this.ruleForm.isDoctorExamine,
            dosage_id: this.ruleForm.dosageId,
            dosage_name: this.curDosageName,
            process_id: this.ruleForm.processId,
            assist_remark: this.ruleForm.assistRemark,
            medicine: {
              count: this.ruleForm.count,
              per_day_count: this.ruleForm.perDayCount,
              per_count: this.ruleForm.perCount,
              per_num: this.ruleForm.perNum,
              per_day_num: this.ruleForm.perDayNum,
              use_day: this.ruleForm.useDay,
						},
            medication_method: this.ruleForm.medicationMethod,
            taboo: this.ruleForm.taboo,
            doctor_advice: this.ruleForm.doctorAdvice,
            make_drug_day: makeDrugDayNumber,
            drug: drugList,
            diseases: this.ruleForm.diseases,
            desc: this.ruleForm.desc,
					}
          var res = await postAddPhotoDrug(id, data)
          console.log(res)
					if(res.code == 200) {
            this.$emit('getList')
            this.$emit('closeDrawer', false)
					}
        } else {
          console.log('error submit!!');
          return false;
        }
      });
      console.log('确认')
		}
  }
}
</script>

<style scoped lang="scss">
	/*竖杠+后面文本的样式*/
	.required {
		margin-top: 3px;
		display: inline-block;
		width: 4px;
		height: 15px;
		background-color: #2362FB;
	}
	
	.required + span {
		display: inline-block;
		margin-left: 4px;
		vertical-align: top;
		font-weight: bold;
		font-size: 14px;
	}
	
	.pres-images {
		margin-top: 10px;
		display: flex;
		justify-content: start;
		
		.pres-image-item {
			margin-right: 10px;
		}
	}
	
	.pres-basic-info {
		margin-top: 20px;
		
		.pres-basic-info-item {
			margin-right: 30px;
		}
	}
	
	.pres-basic-info-jixin {
		width: 950px;
		.pres-basic-info-jixin-item{
			margin-right: 40px;
		}
	}
	
	.from-item-length800 {
		width: 800px;
		margin-right: 5px;
		margin-left: 5px;
		
		.el-form-item__content {
			width: 800px;
		}
		
		.el-select {
			width: 800px !important;
		}
		
		.el-input {
			width: 800px !important;
		}
	}
	
	.from-item-length400 {
		width: 400px;
		margin-right: 5px;
		margin-left: 5px;
		
		.el-form-item__content {
			width: 400px;
		}
		
		.el-select {
			width: 400px !important;
		}
		
		.el-input {
			width: 400px !important;
		}
	}
	
	.from-item-length200 {
		width: 200px;
		margin-right: 5px;
		margin-left: 5px;
		
		.el-form-item__content {
			width: 200px;
		}
		
		.el-select {
			width: 200px !important;
		}
		
		.el-input {
			width: 200px !important;
		}
	}
	
	.from-item-length150 {
		width: 150px;
		margin-right: 5px;
		margin-left: 5px;
		
		.el-form-item__content {
			width: 150px;
		}
		
		.el-select {
			width: 150px !important;
		}
		
		.el-input {
			width: 150px !important;
		}
	}
	
	
	.from-item-length120 {
		width: 120px;
		margin-right: 5px;
		margin-left: 5px;
		
		.el-form-item__content {
			width: 120px;
		}
		
		.el-select {
			width: 120px !important;
		}
		
		.el-input {
			width: 120px !important;
		}
	}
	
	.from-item-length {
		width: 75px;
		margin-right: 5px;
		margin-left: 5px;
		
		.el-form-item__content {
			width: 75px;
		}
		
		.el-select {
			width: 75px !important;
		}
		
		.el-input {
			width: 75px !important;
		}
	}
	
	/*/deep/ .el-form-item {*/
	/*		.el-form-item__label{*/
	/*			padding: 0 0 1px 0 !important;*/
	/*		}*/
	/*	}*/
	
	.drug-list{
		display: flex;
		flex-wrap: wrap;
		.drug-list-item{
			margin-top: 10px;
			flex-shrink: 0;
			display: flex;
			margin-right: 40px;
		}
	}
	.footer-button{
		display: flex;
		justify-content: center;
		margin-top: 20px;
		margin-bottom: 10px;
	}
	
	.img-top{
		position: relative;
		width: 720px;
		height: 720px;
		background-color: #fff;
		padding: 20px;
		float: left;
		overflow: hidden;
		.img-top-item{
			position: absolute;
			width: 700px;
			height: 700px;
			top: 5px;
			left: 7px;
			cursor: move;
		}
	}
</style>
